.StepRangeSlider {
  position: relative;
  left: -22px;
  width: calc(100% + 44px);
  padding: 5px 2px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.StepRangeSlider__track {
  position: relative;
  left: -22px;
  height: 5px;
  width: calc(100% + 44px);
  background-color: #e9e9e9;
  box-sizing: border-box;
  /*-webkit-appearance: slider-horizontal;*/
}

.StepRangeSlider__handle {
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-50%, -50%, 0);
}

.StepRangeSlider__thumb {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background: #082339;
  border: 1px solid #888;
  cursor: pointer;
  /*-webkit-appearance: sliderthumb-horizontal;*/
}

.StepRangeSlider__thumb:active {
  background-color: #082339;
}

.StepRangeSlider__tooltip {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .StepRangeSlider {
    left: 0;
    width: 100%;
  }

  .StepRangeSlider__track {
    left: -44px;
    width: calc(100% + 88px);
  }

  .StepRangeSlider__thumb {
    width: 88px;
  }
}
