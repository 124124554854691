@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ityped-cursor {
  font-size: 1.5rem;
  opacity: 1;
  animation: blink 0.3s infinite;
  animation-direction: alternate;
}

body {
  background-color: #fcfdff;
  min-width: 320px;
}

.login:before {
  content: "";
  margin-left: -48%;
  background-image: url(../public/img/bg-login-screen.svg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .login:before {
    display: none;
  }

  .video-session-height {
    height: calc(100vh - 70px);
  }
}

.white-outline {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-500 focus:ring-white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-player-object-fit-cover video {
  object-fit: cover; /* fit video in react-player in Expert view */
}
.react-player-object-fit-contain .react-player-object-fit-cover video {
  object-fit: contain; /* fit video in react-player in Expert view */
}

.slick-prev,
.slick-next {
  color: #000000 !important;
  z-index: 2;
}

.slick-prev {
  box-shadow: 10px 0 15px 15px rgb(255 255 255);
}

.slick-next {
  box-shadow: -10px 0 15px 15px rgb(255 255 255);
}

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.tippy-wrapper .tippy-arrow {
  color: #000;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  100% {
    opacity: 0;
  }
}

.scrollbar-hidden {
  overflow: hidden !important;
}

.scrollbar-progress-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-progress-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-progress-visible::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scrollbar-progress-visible::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.no-touch {
  -webkit-touch-callout: none;
}
@media (min-width: 1024px) {
  .thank-you-swiper {
    padding-bottom: 72px !important;
  }
}

@media (max-width: 1023px) {
  .thank-you-swiper.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 52px !important;
    width: calc(100vw - 16px);
    left: 8px !important;
    right: 0 !important;
    padding: 20px;
    position: fixed !important;
    background-color: #fff;
  }
}

@media (min-width: 1024px) {
  .thank-you-swiper.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0 !important;
  }
}

.thank-you-swiper .swiper-slide,
.thank-you-swiper .swiper-slide.swiper-slide-duplicate {
  opacity: 0 !important;
}

.thank-you-swiper .swiper-slide.swiper-slide-active,
.thank-you-swiper .swiper-slide.swiper-slide-duplicate-active {
  opacity: 100 !important;
}

.thank-you-swiper.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 30px;
  height: 12px;
  border-radius: 12px;
  background: #97a3b8;
  margin: 0 3px;
}

.thank-you-swiper.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #0f172a;
}

.animated-grow:hover {
  animation: grow 0.8s forwards ease;
}

.quick-session > div[data-tippy-root] {
  right: 0 !important;
  left: 0 !important;
}

.tippy-box[data-theme~="bottom-nav"] {
  background-color: transparent;
  width: calc(100vw - 10px);
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.tippy-box[data-theme~="bottom-nav"] .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~="bottom-nav"] .tippy-arrow {
  color: #fff;
}

.text-shadow {
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
}

.hideSessionUi {
  opacity: 0;
  transition: opacity 1000ms;
}

.showSessionUi {
  opacity: 1;
  transition: opacity 500ms;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.f-price {
  opacity: 0;
  height: 1px !important;
  margin-bottom: 10px !important;
}

.ipsp-modal {
  max-width: 430px !important;
  height: 680px !important;
}

@media (max-width: 360px) {
  .ipsp-modal {
    max-width: 350px !important;
    height: 600px !important;
    border-radius: 0.75rem;
    margin-top: 80px !important;
  }
}
@media (min-width: 370px) and (max-width: 375px) {
  .ipsp-modal {
    position: absolute;
    max-width: 355px !important;
    height: 600px !important;
    border-radius: 0.75rem;
    margin-top: 40px !important;
    margin-left: 10px !important;
  }
}
@media (min-width: 376px) and (max-width: 399px) {
  .ipsp-modal {
    max-width: 362px !important;
    height: 705px !important;
    border-radius: 0.75rem;
    margin-left: 14px !important;
    margin-top: 73px !important;
    position: absolute;
  }
}
@media (min-width: 400px) and (max-width: 499px) {
  .ipsp-modal {
    max-width: 370px !important;
    height: 710px !important;
    border-radius: 0.75rem;
    margin-left: 24px !important;
    margin-top: 96px !important;
    position: absolute;
  }
}
@media (min-width: 500px) and (max-width: 610px) {
  .ipsp-modal {
    max-width: 400px !important;
    height: 600px !important;
    border-radius: 0.75rem;
    position: absolute;
    margin: 69px !important;
  }
}
@media (min-width: 600px) and (max-width: 810px) {
  .ipsp-modal {
    max-width: 400px !important;
    height: 700px !important;
    border-radius: 0.75rem;
    position: absolute;
    margin: 184px !important;
  }
}
@media (min-width: 820px) and (max-width: 850px) {
  .ipsp-modal {
    max-width: 398px !important;
    height: 700px !important;
    border-radius: 0.75rem;
    position: absolute;
    margin: 212px !important;
  }
}
@media (min-width: 851px) and (max-width: 900px) {
  .ipsp-modal {
    max-width: 420px !important;
    border-radius: 0.75rem;
    position: absolute;
    height: 580px !important;
  }
}

@media (min-width: 900px) and (max-width: 912px) {
  .ipsp-modal {
    max-width: 405px !important;
    height: 710px !important;
    border-radius: 0.75rem;
    margin-top: 264px !important;
  }
}

.f-pb-32 {
  padding-bottom: 0 !important;
}

.f-merchant-name,
.f-merchant-url,
.f-order-desc,
.f-security,
.f-title-security,
.f-mb-16 {
  opacity: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 0px !important;
}
.f-title-security svg {
  opacity: 0 !important;
  margin: 0 !important;
}
.f-center-wrap {
  padding: 0 !important;
}

#qr-code-input:focus {
  --swiper-theme-color: initial;
}
#qr-shaded-region {
  overflow: hidden;
}

.plyr-component-class {
  display: block;
}
.plyr-component-class-small {
  display: block;
}
.plyr-component-class-small
  .plyr:not(.plyr--fullscreen, .plyr:fullscreen)
  video {
  display: block;
  object-fit: contain;
  width: 24vh;
  height: 24vh !important;
  margin: 0 auto;
}

.plyr-small-card .plyr {
  right: 35px;
  bottom: 37px;
}

.plyr-component-class .plyr:not(.plyr--fullscreen, .plyr:fullscreen) video {
  display: block;
  object-fit: contain;
  width: auto;
  height: 48vh !important;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .plyr-component-class .plyr:not(.plyr--fullscreen, .plyr:fullscreen) video {
    display: block;
    object-fit: contain;
    width: auto;
    height: 208px !important;
    margin: 0 auto;
  }
  .plyr-component-class-small
    .plyr:not(.plyr--fullscreen, .plyr:fullscreen)
    video {
    display: block;
    object-fit: contain;
    width: auto;
    height: 207px !important;
    margin: 0 auto;
  }

  .plyr.plyr--fullscreen video {
    display: block;
    object-fit: contain;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

.plyr.plyr--fullscreen video {
  display: block;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.videoContainer {
  position: relative;
}

.plyr__video-embed {
  position: relative;
}

.plyrPlaylistWrapper {
  --plyr-color-main: black;
}
.plyrPlaylistWrapper ul::-webkit-scrollbar {
  width: 6px;
}

.plyrPlaylistWrapper ul::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.plyrPlaylistWrapper ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--plyr-color-main);
}

.plyrPlaylistWrapper {
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  padding: 0.25em 0.25em 0.5em 0.25em;
}

.plyrPlaylistWrapper .plyrPlaylist {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  margin-top: 0;
  padding: 6px 4px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 8px 0px inset;
}

.plyrPlaylistWrapper ul {
  padding: 0;
  margin: 0;
  max-height: 12em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.playlistItem {
  display: flex;
  list-style: none;
  color: #c9c9c9;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 0.5em 0.25em 0.5em 0.75em;
  margin-bottom: 3px;
  font-size: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;
  cursor: pointer;
  transition: all ease 0.25s;
}

.playlistItem.plsPlaying,
.playlistItem:hover {
  background-color: rgba(255, 255, 255, 0.09);
}

.playlistItem.plsPlaying {
  color: #f8fafc;
  font-weight: 900;
}

.plyrMiniPoster {
  width: auto;
  height: 22px;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  margin-right: 10px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

@media screen and (max-width: 767px) {
  .plyrPlaylistWrapper ul {
    height: 260px;
  }
}

.transform-custom-1-6 {
  transform: translateX(-16.666667%);
}
.transform-custom-1-2 {
  transform: translateX(-50%);
}

.webkit-tap-highlight-color {
  -webkit-tap-highlight-color: transparent;
}

/* WebKit */
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #64748b;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.video-guide-btn-color {
  background: linear-gradient(to right, #6749d5, #dc40a4, #fd7842);
}

@layer components {
  .message {
    @apply transition-transform duration-300 ease-out;
  }

  .message.swiped {
    animation: slideBack 0.5s forwards;
  }

  @keyframes slideBack {
    from {
      transform: translateX(-60%);
    }
    to {
      transform: translateX(0);
    }
  }
}

.video-wrapper {
  position: relative;
  background-size: cover !important;
  background-position: center !important;
  overflow: hidden;
}
.plyr__video-wrapper {
  background: transparent !important;
}
.video-wrapper::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  background: inherit;
  filter: blur(5px);
  z-index: 1;
}

.plyr__video-wrapper > * {
  position: relative;
  z-index: 2;
}
.video-wrapper > * {
  position: relative;
  z-index: 2;
}

.plyr-my
  .plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--paused.plyr--stopped.plyr--pip-supported.plyr__poster-enabled,
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--paused.plyr--stopped.plyr--pip-supported.plyr__poster-enabled {
  position: relative;
  z-index: 2;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--pip-supported.plyr__poster-enabled.plyr--paused {
  position: relative !important;
  z-index: 2 !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--pip-supported.plyr__poster-enabled.plyr--playing.plyr--hide-controls {
  position: relative !important;
  z-index: 2 !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--pip-supported.plyr__poster-enabled.plyr--playing {
  position: relative !important;
  z-index: 2 !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--paused.plyr--stopped.plyr--airplay-supported.plyr--is-touch.plyr__poster-enabled {
  position: relative !important;
  z-index: 2 !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--airplay-supported.plyr--is-touch.plyr__poster-enabled.plyr--playing.plyr--hide-controls {
  position: relative !important;
  z-index: 2 !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--airplay-supported.plyr--is-touch.plyr__poster-enabled.plyr--paused.plyr--stopped {
  position: relative !important;
  z-index: 2 !important;
}
.plyr__poster {
  background: transparent !important;
}
.plyr--video .plyr__controls {
  background: transparent !important;
}
.plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--fullscreen-enabled.plyr--airplay-supported.plyr--is-touch.plyr__poster-enabled.plyr--paused {
  position: relative !important;
  z-index: 2 !important;
}

@media (max-height: 667px) {
  .scroll-purchase-modal {
    max-height: 60vh;
  }
}
