.react-datepicker {
  font-family: "Inter" !important;
  font-size: 1rem;
  width: 100% !important;
  border: none !important;
  background-color: #fcfdff !important;
}
.datepicker-header {
  font-size: 1rem !important;
}

.react-datepicker__header {
  background-color: #fcfdff !important;
  border-bottom: none !important;
  padding-top: 0 !important;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4rem;
}

.react-datepicker__day-name {
  width: 2.5rem !important;
  margin: 0 5px;
  font-weight: 500;
  color: #97a3b8 !important;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 !important;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day {
  display: grid !important;
  align-items: center;
  color: #97a3b8;
  font-weight: 500;
  width: 2.5rem !important;
  height: 2.5rem !important;
  margin: 4px;
  border-radius: 30% !important;
}

.react-datepicker__day:hover {
  background-color: unset;
}

.react-datepicker__day--today {
  font-weight: normal;
}

.react-datepicker__day--selected {
  font-weight: bold;
  color: #fcfdff !important;
  background-color: #27ae60 !important;
  outline: none;
}

.react-datepicker__day--selected.react-datepicker__day--disabled {
  background: none !important;
  font-weight: normal;
  color: #6d7175 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--in-range {
  border-color: #27ae60;
}

@media (max-width: 1500px) {
  .react-datepicker__day-name {
    font-size: 0.875rem;
    margin: 0 4px;
  }

  .react-datepicker__day {
    font-size: 0.875rem;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

div[aria-disabled="false"] {
  border: 1px #27ae60 solid;
  color: #0f172a;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--weekend {
  background-color: #27ae60 !important;
  border: 1px #27ae60 solid;
}

div[aria-disabled="false"]:hover {
  color: #fff;
  background-color: #27ae60;
}

div[aria-disabled="true"] {
  cursor: default;
}

.react-datepicker__day--outside-month {
  opacity: 0.5;
}

.react-datepicker__day--unavailable {
  border: 1px solid #ff4646 !important;
  color: #000 !important;
}

.react-datepicker__day--selected.react-datepicker__day--unavailable {
  background-color: #f8fafc !important;
}

.react-datepicker__day--unavailable[aria-disabled="false"]:hover {
  background-color: #f8fafc;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.313rem !important;
}
